<template>
  <div class="request-group">
    <div class="container">
      <div class="request-group__inner">
        <div class="request-group__item">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form class="form-block" @submit.prevent="handleSubmit(onGroupRequest)">
              <div class="form-block__body">
                <div v-if="false" class="form-row">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Full Name">
                </div>
                <div v-if="false" class="form-row">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Email">
                </div>
                <ValidationProvider
                  tag="div"
                  rules="required"
                  name="Group name"
                  :class="['form-row']"
                  v-slot="{ errors }">
                  <input
                    v-model="group.name"
                    class="form-control"
                    type="text"
                    placeholder="Group Name">
                  <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                </ValidationProvider>
                <div class="form-row">
                  <select class="form-control" v-model="group.category_id">
                    <option v-for="category in categories"
                            :key="category.id + category.name"
                            :value="category.id">{{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="form-row">
                  <select class="form-control" v-model="group.style">
                    <option v-for="style in styles"
                            :key="style.id + style.name"
                            :value="style.id">{{ style.name }}
                    </option>
                  </select>
                </div>
                <div class="form-row">
                  <select class="select_categories form-control" v-model="group.type">
                    <option v-for="option in options"
                            :key="option.value"
                            :value="option.value">{{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="form-row">
                  <select class="select_categories form-control" v-model="group.channels">
                    <option v-for="channel in channels"
                            :key="channel.id"
                            :value="channel.id">{{ channel.name }}
                    </option>
                  </select>
                </div>
                <template v-if="group.type === '2'" name="fade" tag="div">
                  <ValidationProvider
                    tag="div"
                    rules="required"
                    name="You Name"
                    :class="['form-row']"
                    v-slot="{ errors }">
                    <input
                      v-model="group.owner_name"
                      class="form-control"
                      type="text"
                      placeholder="You Name">
                    <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                  </ValidationProvider>
                  <ValidationProvider
                    tag="div"
                    rules="required"
                    name="You Address"
                    :class="['form-row']"
                    v-slot="{ errors }">
                    <input
                      v-model="group.owner_address"
                      class="form-control"
                      type="text"
                      placeholder="You Address">
                    <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                  </ValidationProvider>
                  <div class="form-row">
                    <input
                      v-model.number="group.cost"
                      step="0.01"
                      class="form-control"
                      type="number"
                      placeholder="Membership cost per month">
                  </div>
                </template>
                <ValidationProvider
                  tag="div"
                  rules="required|max:400"
                  name="Description"
                  :class="['form-row']"
                  v-slot="{ errors }">
                  <textarea
                    v-model="group.description"
                    class="form-control"
                    cols="30"
                    rows="10"
                    maxlength="401"
                    placeholder="Group description">
                  </textarea>
                  <span v-if="errors.length" class=require_field>{{ errors[0] }}</span>
                </ValidationProvider>
                <Alert :variant="variant" v-if="text">{{ text }}</Alert>
                <div class="form-row">
                  <label class="custom_checkbox_label">
                    <input
                      class="form-control custom_checkbox"
                      type="checkbox"
                      placeholder="privacy_terms"
                      @change="isReadPrivacy = !isReadPrivacy"> I have read and agree with the&nbsp;
                    <router-link
                      :to="{name: 'PrivacyPage'}"
                      style="color:#1d9dd9; margin-left: 3px;"
                    > Privacy Policy
                    </router-link>
                  </label>
                </div>
                <div class="btns-group--right">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    :disabled="!isReadPrivacy"
                  >Submit
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Alert from '@/components/Alert.vue';

import GroupService from '@/services/GroupService';
import DictionaryService from '@/services/DictionaryService';

import exception from '@/mixins/exception';
import content from '@/mixins/content';
import alert from '@/mixins/alert';

export default {
  name: 'ProfileGroupsRequest',
  mixins: [exception, content, alert],
  components: { Alert },
  data() {
    return {
      group: {
        name: '',
        type: '1',
        cost: 0,
        description: '',
        category_id: undefined,
        style: undefined,
        channels: undefined,
        owner_name: '',
        owner_address: '',
      },
      options: [
        { text: 'Open', value: '1' },
        { text: 'Private', value: '2' },
      ],
      categories: [],
      styles: [],
      channels: [],
      isReadPrivacy: false,
    };
  },
  async created() {
    await this.getCategories();
    await this.getStyles();
    await this.getChannels();
  },
  methods: {
    async onGroupRequest() {
      try {
        await GroupService.groupsRequest(this.group);

        this.$router.push({ name: 'profileGroups', params: { type: 'request' } });
      } catch (e) {
        console.log(e.response.data);
        console.log({ textArg: e.response.data[0].message, variantArg: 'danger' });
        this.showAndHideAlertSuccess(e.response.data[0].message, 'danger');
      }
    },
    async getCategories() {
      let res = null;

      try {
        res = await DictionaryService.categories();

        this.group.category_id = res.data[0].id;
        this.categories = res.data;
      } catch (e) {
        this.categories = [];
        this.handleException(e);
      }
    },
    async getStyles() {
      let res = null;

      try {
        res = await DictionaryService.styles();

        this.group.style = res.data[0].id;
        this.styles = res.data;
      } catch (e) {
        this.categories = [];
        this.handleException(e);
      }
    },
    async getChannels() {
      let res = null;

      try {
        res = await DictionaryService.getChannelsCategories();
        this.group.channels = res.data[0].id;
        this.channels = res.data;
      } catch (e) {
        this.channels = [];
        this.handleException(e);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.require_field {
  padding-left: 10px;
  font-size: 14px;
  color: #FF0000;
  font-weight: 500;
}

.custom_checkbox_label {
  display: flex;
  // align-items: center;
}

.custom_checkbox {
  display: inline-block;
  width: 20px;
  height: 20px;
  min-height: auto;
  margin-right: 5px;
}

button[type=submit]:disabled {
  background-color: #cccccc;
  color: #FFF;
}

button[type=submit]:disabled:hover {
  color: #FFF;
  cursor: default;
}
</style>
